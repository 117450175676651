import React, {useEffect} from 'react';
import { Fade } from "react-awesome-reveal";

import EntryItem from './EntryItem';

import footerLogo from "../assets/images/GF-Fan Faves-Masthead Horizontal-GREEN.png";

export default function EntryCategory(props) {
    const entries = props.entries;
    const img = props.title.replaceAll(' ', '').replaceAll('/','').toLowerCase();

    useEffect(()=>{
        
    }, []);

    function getEntryItems(entryItems) {
        if(entryItems.length != 0){
            return entryItems.map((item) => {
                return (
                    <EntryItem 
                        key={item["name"]} 
                        name={item["name"]} 
                        id={item["id"]} 
                        photo={item["profilepic"]} 
                        votes={item["votes"]}
                        rank={item["rank"]}
                        category={item["category"]}
                        />)
            })
        }
    }
    
    return(
        <>
        <div className={`category-wrapper order-campaign ${img}`}>
            <div className="category-header">
                {String(props.title )== "Hot 100" ? "" :
                    <img src={require("../assets/images/categoryIcons/"+img+".png")} alt={props.title} className="category-header-icon" />
                }
                <span className='category-header-title'>
                    {(String(props.title )== "Hot 100" ? "Trending Faves" : props.title)}
                </span>
            </div>
            <div className="category-body">
                {getEntryItems(entries)}
            </div>
        </div>
        </>
    );
}