import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import { useAppContext } from './libs/contextLib';
import { useIsAuthenticated} from 'react-auth-kit';
import Logout from './components/Logout';
import DashboardWinners from './containers/DashboardWinners';
import LeaderboardWinners from './containers/LeaderboardWinners';
const Footer = React.lazy(() => import("./components/Footer"));
const Login = React.lazy(() => import('./containers/Login'));
const Dashboard = React.lazy(() => import('./containers/Dashboard'));
const Leaderboard = React.lazy(() => import('./containers/Leaderboard'));
const Loader = React.lazy(() => import('./components/Loader'));
const Navigation = React.lazy(() => import('./components/Navigation'));

export default function Router() {
    
    const {userToken, setUserToken, loading, setLoading, postLoader, setPostLoader, openNav, setOpenNav} = useAppContext();	
    const authkitAuthenticated = useIsAuthenticated();

    function toggleNav(){
        setOpenNav(!openNav);
    }

    return (
		<>	
        {/* <Loader class="post-loader"/> */}
        {loading ? (<Loader class={postLoader?"post-loader":""}/>) : ("")}
        {authkitAuthenticated() ? (<Navigation/>) : ("")}
        <main className={authkitAuthenticated() ? "authenticated" : ""}>
            <div className={openNav ? "nav-button opened" : "nav-button"} onClick={toggleNav}>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <BrowserRouter>
                {authkitAuthenticated()?
                    <Routes>								
                        <Route path="/" element={<DashboardWinners/>} />
                        <Route path="/leaderboard" element={<LeaderboardWinners/>} />
                        <Route path="/logout" element={<Logout/>} />
                        <Route path="*" element={<Navigate to="/" />} />
                    </Routes>
                :
                    <Routes>								
                        <Route path="/login" element={<Login/>} />
                        <Route path="*" element={<Navigate to="/login" />} />
                    </Routes>
                }
            </BrowserRouter>
        </main>
        <Footer/>
        </>
    );
}