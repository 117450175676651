
import React, {useEffect, useState, useRef} from 'react';
import { Fade, Zoom, Slide } from "react-awesome-reveal";
import { useAppContext } from '../../libs/contextLib';

export default function Category(props) {
    const {activeCategory, setActiveCategory, merchants, setMerchants, categories} = useAppContext();	
    const [loading, setLoading] = useState(true);

    var categoryName = activeCategory.replaceAll('/', '').replaceAll(' ','');
    useEffect(() => {
        setLoading(false);

        getAllFromCategory(activeCategory);
    },[]);

    async function getAllFromCategory(category){
        // console.log(document.getElementById("results-inner-wrapper") != null);
        if(document.getElementById("results-inner-wrapper") != null){
            document.getElementById("results-inner-wrapper").scrollTop = 0;
        }
        setMerchants([]);

        try {
            const data = {};
                data["action"] = "getallfromonecat";
                data["category"] = category;
                data["gateway"] = process.env.REACT_APP_GATEWAY;

            var json = JSON.stringify(data);

            const response = await fetch(
                "https://k85nelxwo8.execute-api.ap-southeast-1.amazonaws.com/default/Grab_Items", 
                {
                    method: 'POST',    
                    headers: {'Content-Type': 'application/json'},        
                    body: json
                });
            const res = await response.json(); 
            const placeholder = [];
            res[0].items.forEach((value, index) =>{
                if(!value.fake){
                    placeholder.push({
                        "rank": value.rank,
                        "votes": value.votes,
                        "merchant": value.merchant, 
                        "product": value.product_name, 
                        "category": value.category,
                        "link": value.link
                        });
                }
            });

            // const merchantList = [...new Set(placeholder)].sort();
            const merchantList = placeholder
                // .filter((item, pos) => {
                //     const currIndex = placeholder.findIndex(x => x.merchant === item.merchant);
                //     return currIndex == pos;
                // })
                .filter((item, pos) => {
                    if(item.category == category){
                        return item;
                    }
                })
                .map((item, pos) => {
                    var el = item.product;
                    const length = item.product.length;
                    if(el.charAt(0)=="\"" && el.slice(-1)=="\""){
                        el = el.slice(1, -1);
                    }
                    else if(el.charAt(0)=="\""){
                        el = el.substring(1, length);
                    }
                    else if(el.slice(-1)=="\""){
                        el = el.substring(0, length - 1);
                    }

                    item.product = el;
                    
                    return item;
                })
                .sort((a, b) => {
                    let fa = a.rank,
                        fb = b.rank;
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });
            
            const items = [];
            merchantList.forEach((value, index) => {
                // console.log(value)
                items.push(
                    <div key={index+"-"+value.product} >
                        <a  
                            href={value.link} 
                            target='_blank'
                            className='results-item'>
                            <span className='rank-wrapper'><b className='rank'>{value.rank}</b></span>
                            <span>{value.product}</span>
                            <span>{value.merchant}</span>
                        </a>
                    </div>
                );
            });

            setTimeout(() => {
                setMerchants(items);
            }, 200);
        } catch (error) {
            alert(error);
        }
        
    }

    async function getItems(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const category = e.target.querySelector(".categorybutton").getAttribute("category");
        if(category == activeCategory){
            setMerchants("empty");
            setActiveCategory("");
        }else{
            setActiveCategory(category);
            getAllFromCategory(category);
        }
    }

    function getCategories() {
        return categories.map((category)=>{
            const categoryName = category.replaceAll('/', '').replaceAll(' ','');
            if(categoryName == "Hot100"){
                return
            }
            return(
                <div key={categoryName+"wrapper"}
                    category={category}
                    onClick={(e)=>getItems(e)}
                    className={`categorybuttonWrapper ${categoryName+"-wrapper"} ${activeCategory==category ? "active" : ""}`}>
                <Zoom 
                    fraction=".5" 
                    triggerOnce 
                    duration={300} 
                    key={categoryName+"anim"}>
                    <img 
                        key={categoryName}
                        src={require(`../../assets/images/categoryButtons/${categoryName}${activeCategory==category?"":"-alt"}.png`)} 
                        id={categoryName}
                        className={`categorybutton`}
                        alt={category} 
                        category={category}
                        />  
                </Zoom>
                </div>
            );
        })
    }
    
    return(
        <>
        <div className={`categories-wrapper ${categoryName}`}>
            {getCategories()}
            <Zoom fraction=".5" triggerOnce duration={300} className="results-wrapper" id="results-wrapper">
                {merchants == "empty" ? "" :
                    (merchants.length == 0 ? 
                        <div className='results-loader'>
                            <div className="results-loader-wrapper">
                                <div className="lds-loader"><div></div><div></div><div></div></div>
                            </div>
                        </div>
                        :
                        <div className="results-inner-wrapper" id='results-inner-wrapper'>
                            <div>
                                <span  
                                    className='results-item header'>
                                    <span className='rank-wrapper'></span>
                                    <span>Product</span>
                                    <span>Merchant</span>
                                </span>
                            </div>
                            {merchants}
                        </div>)
                }
            </Zoom>
        </div>
        </>
    );
}