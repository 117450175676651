import React, {useState, useEffect, useRef} from 'react';
import { uploadFile } from "react-s3";
import { useAppContext } from '../libs/contextLib';
import { Modal} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import { FacebookShareButton } from "react-share";
import html2canvas from 'html2canvas';
import 'react-toastify/dist/ReactToastify.css';
import config from "../config";
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import * as faBrands from '@fortawesome/free-brands-svg-icons';

import imageFrame from "../assets/images/image-frame.png";

import placeholderPhoto from "../assets/images/productPlaceholder.png";
import uploadphotoIcon from "../assets/images/image-input.png";
// import { error } from 'console';

window.Buffer = window.Buffer || require("buffer").Buffer; 

async function getURLFromService(link){
    // The real implementation would make a network call here.
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return link;
}

export default function EntryItem(props) {
    const {userToken, setUserToken, loading, setLoading, postLoader, setPostLoader, userData, userAttrAvailable, setUserAttrAvailable } = useAppContext();
    const [imageModalShow, setImageModalShow] = useState(false);
    
    const [showModal, setShowModal] = useState(false);
    const [imgFile, setImgFile] = useState();
    const [imgDisplay , setImgDisplay] = useState(props.photo);

    const [currVotes, setCurrVotes] = useState(0);
    const [requiredVotes, setRequiredVotes] = useState(10000);
    const [categ, setCateg] = useState("Gold");

    const [imgReq_Size, setImgReq_Size] = useState(true);
    const [imgReq_Type, setImgReq_Type] = useState(true);
    const [imgReq_Height, setImgReq_Height] = useState(true);
    const [imgReq_Width, setImgReq_Width] = useState(true); 

    const [url, setUrl] = useState("none");
    const exportRef = useRef();
    const shareButton = useRef();

    const imgLink = "https://cdn.grabfanfaves.com/products/";
    
    useState(()=>{
        // console.log(props);
        if(props.category === "Hot 100"){
            setCateg("Trending Faves");
            setRequiredVotes(5000);
        }
    }, [imgDisplay]);

    const file = useRef(null);
    const changePhoto = (e) => setShowModal(true);
    const handleClose = () => {
        setShowModal(false); 
        setImgReq_Type(true);
        setImgReq_Size(true);
        setImgReq_Height(true);
        setImgReq_Width(true);
        setImgFile();
    };

    const modalClose = () => {
        setImageModalShow(false);
    };

    const acceptedFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp"];

    useEffect(() => {
        if(props.votes !== null){
            setCurrVotes(props.votes);
        }
    }, [imgReq_Size, imgReq_Height, imgReq_Width, imgReq_Type]);

    function handleFileChange(event) {
        setLoading(true);
        file.current = event.target.files[0];

        var img = new Image();
            img = document.createElement("img");
            img.src = URL.createObjectURL(file.current);
        
        img.onload = function () {
            setImgReq_Type(acceptedFileTypes.includes(file.current.type));
            setImgReq_Size(file.current.size <= 10485760);
            setImgReq_Height(img.height >= 1080);
            setImgReq_Width(img.width >= 1080);
            setImgFile(img.src);

            setLoading(false);
        };
    }

    async function handleSubmit(event) {
        setLoading(true);
        event.preventDefault();
        event.stopPropagation();

        if(imgReq_Size && imgReq_Height && imgReq_Width && imgReq_Type){
            uploadFile(file.current, config.s3_product)
                .then(async (s3Response) => {
                    const passData = {};
                        passData["action"] = "uploadphoto";
                        passData["profilepic"] = file.current["name"];
                        passData["mexid"] = userData["mexID"];
                        passData["prodid"] = event.target.getAttribute("data-target");
                        passData["gateway"] = process.env.REACT_APP_GATEWAY;
                        passData["accessToken"] = userToken;

                    const json = JSON.stringify(passData);

                    const response = await fetch(
                        'https://t52t60dk2a.execute-api.ap-southeast-1.amazonaws.com/default/Grab_Merchant', 
                        {
                            method: 'POST',    
                            headers: {
                                'Content-Type': 'application/json'
                            },        
                            body: json
                        });
                    const res = await response; 

                    if(res.status == 200){
                        setImgDisplay( passData["profilepic"]);
                        toast.info('Photo successfully uploaded', config.toast);
                        setShowModal(false);
                        setLoading(false);

                    }
                })
                .catch((err) => {
                    toast.error(err.message, config.toast); 
                    setLoading(false);}
                    );
        }else{
            toast.error('Sorry, your photo does not meet the requirements.', config.toast);
            setLoading(false);
            return;
        }
    }

    async function waitForElement(selector, timeout = 5000) {
        const start = Date.now();
        
        while (Date.now() - start < timeout) {
            const el = document.querySelector(selector);
            if (el) {
            return el;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        
        return null;
    }

    async function waitForImageDisplay(link, timeout = 5000) {
        const start = Date.now();
        
        while (Date.now() - start < timeout) {
            const el = await fetch(link);
            if (el) {
                return el;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        
        return null;
    }

    const uploadImage = async(mexname, productname) => {
        setImageModalShow(true);

        if (imgDisplay !== "") {
            const test = await imgLink+imgDisplay;
        }

        const canvas = await html2canvas(await waitForElement('#image-to-fetch'),  { useCORS: true });
        const image = canvas.toDataURL("image/png", 1.0);

        const base64string = await image;

        const filename = mexname + '-' + productname + Date.now() + '.png'
        const data = {base64string, filename};

        await fetch('https://gw3ymyb8cj.execute-api.ap-southeast-1.amazonaws.com/default/Grab_base64', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        }).then(response => {
            if(response.status == 200){
                if (url === "none") {
                    async function getImage(){
                        const newUrl = await getURLFromService('https://cdn.grabfanfaves.com/socialmedia/' + filename);

                        console.log(newUrl)
                        setUrl(newUrl);
                    }

                    getImage();
                }
            }
        });
    }
    
    useEffect(() => {
        if (url !== "none") {
            shareButton.current?.click();
            setUrl("none");
        }
    }, [url, shareButton]);

    return(
        <>
        <div className="entry-wrapper">
            <div className="entry-photo">
                <img src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} alt="" className="header-icon" />
                <span onClick={changePhoto} data-id={props.id} className='entry-edit-photo' title='Edit Entry Photo'><FontAwesomeIcon icon={faIcon.faEdit}/></span>
            </div>
            <p className="entry-name">{props.name}</p>
            <div className="entry-ranking">
                {(props.rank !== null)&&(props.rank !== undefined) ? 
                    <h2>{props.rank}</h2> :
                    <h4 className='text-bold'>Unranked</h4>}
                <label>Current Ranking</label>
            </div>
            <div className="entry-votes">
                {/* <h4>{props.votes !== null ? props.votes : "000"} / {Number(10000).toLocaleString()}</h4> */}
                {/* <h4>{Number(5231).toLocaleString()} / {Number(10000).toLocaleString()}</h4>
                <label>Current Votes / Required Votes</label> */}
                <h4>{Number(currVotes).toLocaleString()}</h4>
                <label>Order/Votes</label>

                {/*(currVotes > requiredVotes) ? 
                    <>
                        <label className='mt-2'>You have met the {requiredVotes.toLocaleString()} vote requirement to win {categ} Award!</label>
                    </>
                    :
                    <>
                        <h4 className='mt-2'>{(Number(requiredVotes - currVotes).toLocaleString())}</h4>
                        <label>votes to go <br /> to win the {categ} Award!</label>
                    </>
                */}
            </div>
            <div className="entry-socials">
                <FacebookShareButton ref={shareButton} onClick={async () => await uploadImage(userData.mexName, props.name)} openShareDialogOnClick={url !== "none"} url={url} /*hashtag={'#' + props.name}*/>
                    <a>
                        <FontAwesomeIcon icon={faBrands.faFacebookSquare }/>
                    </a>
                    
                </FacebookShareButton>
                {/* <a href="">
                    <FontAwesomeIcon icon={faBrands.faInstagramSquare}/>
                </a>
                <a href="">
                    <FontAwesomeIcon icon={faBrands.faTwitterSquare}/>
                </a> */}
            </div>
        </div>

        <Modal show={imageModalShow} onHide={modalClose} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='head-btn-container' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please wait for the share dialog to show up</p>
                <div className="photo-container">
                    <div id='image-to-fetch'/*ref={exportRef}*/ className="parent clearfix" style={{backgroundColor:'#3700ff'}}>
                        <img  className='fgPhoto' src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} crossOrigin='anonymous'></img>
                        <img className='bgPhoto' src={imageFrame}></img>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={showModal} onHide={handleClose} className='edit-profile-modal'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <h4 className="text-center m-auto mb-3">UPLOAD PRODUCT PHOTO</h4>
                <form action="" method="post" className='form-update_product_photo text-center'>
                    {/* <p className="mb-1">Click photo to select:</p> */}
                    <div className={`imagefile-wrapper m-auto ${(imgDisplay ? "withImage" : "")}`}>
                        <input type="file" onChange={handleFileChange} name="photo" id="product-photo" accept="image/png, image/jpg, image/jpeg, image/webp"/>
                        <img src={(imgFile) ? imgFile : (imgDisplay ? imgLink+imgDisplay : uploadphotoIcon)} />
                    </div>
                    <div className="imagefile-reqs m-auto mt-2">
                        <p className={imgReq_Size?"correct":"wrong"}>Max Size : <span className='text-underline'>10mb</span> </p>
                        <p className={imgReq_Type?"correct":"wrong"}>Format: <span className='text-underline'> JPEG, JPG, PNG, WebP</span> </p>
                        <p className={imgReq_Width&&imgReq_Height?"correct":"wrong"}>Min Dimensions: <span  className='text-underline'>1080px x 1080px</span> </p>
                    </div>
                    <input data-target={props.id}  type="submit" data-phase="updateProfile" className='btn btn-main-green btn-otp mt-3' value="UPLOAD IMAGE" onClick={handleSubmit}/>
                </form>
            </Modal.Body>
        </Modal>
        </>
    );
}

// https://grabfanfaves.s3.ap-southeast-1.amazonaws.com/products/