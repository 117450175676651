import React, {StrictMode, useEffect, useState, useRef} from 'react';
import { Auth, Amplify } from "aws-amplify";
import AppRoutes from "./Router";
import { AppContext } from './libs/contextLib';
import { useIsAuthenticated, useAuthUser, useSignOut, useAuthHeader } from 'react-auth-kit'
import '@aws-amplify/ui-react/styles.css';
import "./assets/styles/fonts.css";
import "./assets/styles/animate.min.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./assets/styles/layout.scss";
import "./assets/styles/main.scss";
import "./assets/styles/responsive.scss";
import moment from 'moment';
import config from './config';

function App() {
    const authkitAuthenticated = useIsAuthenticated();
    var authKitValue = authkitAuthenticated();
    const authUser = useAuthUser();
    const signOut = useSignOut();
    const authHeader = useAuthHeader();

    // appContext Values
    const [userToken, setUserToken] = useState("");
    const [loading, setLoading] = useState(true);
    const [postLoader, setPostLoader] = useState(false);
    const [userData, setUserData] = useState([]);
    const [merchEntries, setMerchEntries] = useState([]);
    const [userAttrAvailable, setUserAttrAvailable] = useState(false);
    const [openNav, setOpenNav] = useState(false);
    const [categories, setCategories] = useState([
        "Boneless Chicken",
        "Coffee",
        "Pizza",
        "Cold Desserts",
        "Combo Meals",
        "Desserts",
        "Fried Chicken",
        "Fries/Onion RIngs",
        "Healthy Options",
        "Milk Tea",
        "Pasta",
        "Premium Burger",
        "Regular Burger",
        "Rice Meal",
        "Roasted Chicken",
        "Hot 100"]);
    // Leaderboard
    const [merchants, setMerchants] = useState([]);
    const [regionalMerchants, setRegionalMerchants] = useState([]);
    const [trendings, setTrendings] = useState([]);
    const [activeCategory, setActiveCategory] = useState("Premium Burger");
    const [activeRegion, setActiveRegion] = useState("Luzon");

    const [nationalWinners, setNationalWinners] = useState([]);
    const [regionalWinners, setRegionalWinners] = useState([]);
    const [trendingFaveWinners, setTrendingFaveWinners] = useState([]);
    const [nationalItems, setNationalItems] = useState([]);
    const [regionalItems, setRegionalItems] = useState([]);
    const [trendingFaveItems, setTrendingFaveItems] = useState([]);

    const isLive = false;
    var isMobile = false;


    const windowSize = useRef([window.innerWidth, window.innerHeight]);
    var viewport = document.querySelector("meta[name=viewport]");

    if(windowSize.current[0] < 750){
        viewport.setAttribute('content', 'width=device-width, initial-scale=.7, maximum-scale=1.0, user-scalable=0'); 
    }

    if(windowSize.current[0] <= 1100){
        isMobile = true;
    }

    useEffect(() => {
        // signOut();
        // console.log(authkitAuthenticated());
        if(authkitAuthenticated()) {
            const token = authUser().token;

            async function getData(param) {
                const data = {};
                data["action"] = "profile";
                data["accessToken"] = param;
                data["gateway"] = process.env.REACT_APP_GATEWAY;

                const json = JSON.stringify(data);
                var apiLink = ""

                if(isLive){apiLink = 'https://exlex4lhm3.execute-api.ap-southeast-1.amazonaws.com/default/Grab_Response'
                }else{apiLink = "https://38ssiknpqa.execute-api.ap-southeast-1.amazonaws.com/default/DevGrabFunctions"}

                const response = await fetch(
                    apiLink,
                    {
                        method: 'POST',    
                        headers: {
                            'Content-Type': 'application/json'
                        },        
                        body: json
                    });
                const res = await response.json(); 
                const ms = moment(res.UserAttributes[1]["Value"]).valueOf() - Date.now();
                
                if(res.message === "Internal server error" || ms <= 0){
                    logout();
                }

                // setUserData(res.UserAttributes);

                // console.log(param, res, moment(res.UserAttributes[1]["Value"]).format('MMMM Do YYYY, h:mm:ss a'));
                // console.log(res);
                const merchId = res.UserAttributes.find((element) => element["Name"] == "custom:mexid")["Value"];
                const dataMerch = {};
                dataMerch["action"] = "getone";
                dataMerch["merchantID"] = merchId;
                dataMerch["gateway"] = process.env.REACT_APP_GATEWAY;

                const jsonMerch = JSON.stringify(dataMerch);

                if(isLive){apiLink = 'https://exlex4lhm3.execute-api.ap-southeast-1.amazonaws.com/default/Grab_Merchant'
                }else{apiLink = "https://38ssiknpqa.execute-api.ap-southeast-1.amazonaws.com/default/DevGrabFunctions"}
                
                const responseMerch = await fetch(
                    apiLink,
                    {
                        method: 'POST',    
                        headers: {
                            'Content-Type': 'application/json'
                        },        
                        body: jsonMerch
                    });
                const resMerch = await responseMerch.json(); 

                setUserData(resMerch);
            }
            getData(token);
            setUserToken(token);

        } else{
            signOut();
        }      
    }, [authkitAuthenticated()]);

    function logout() {
        signOut();
        setTimeout(() => {
            alert("Sorry, your session timed out. Please login again.");
        }, 500);
    }
    
    return (      
        <StrictMode>
            <AppContext.Provider value={{
                userToken, setUserToken, 
                loading, setLoading, 
                postLoader, setPostLoader, 
                userData, setUserData,
                merchEntries, setMerchEntries,
                categories, setCategories,
                userAttrAvailable, setUserAttrAvailable,
                openNav, setOpenNav,
                activeCategory, setActiveCategory, 
                activeRegion, setActiveRegion,
                merchants, setMerchants, 
                regionalMerchants, setRegionalMerchants,
                trendings, setTrendings,
                nationalWinners, setNationalWinners,
                regionalWinners, setRegionalWinners,
                trendingFaveWinners, setTrendingFaveWinners,
                nationalItems, setNationalItems,
                regionalItems, setRegionalItems,
                trendingFaveItems, setTrendingFaveItems,
                isMobile}}>
                <AppRoutes />   
            </AppContext.Provider>
        </StrictMode>  
    );
}

export default App;
