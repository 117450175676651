import React, {useState, useEffect, useRef} from 'react';
import { uploadFile } from "react-s3";
import { useAppContext } from '../libs/contextLib';
import { Modal} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToastContainer, toast } from 'react-toastify';
import { FacebookShareButton } from "react-share";
import html2canvas from 'html2canvas';
import 'react-toastify/dist/ReactToastify.css';
import config from "../config";
import * as faIcon from '@fortawesome/free-solid-svg-icons';
import * as faBrands from '@fortawesome/free-brands-svg-icons';

import imageFrame from "../assets/images/image-frame.png";

import awardGold from "../assets/images/gold.png";
import awardPlatinum from "../assets/images/platinum.png";
import awardRegional from "../assets/images/regional.png";
import awardTrending from "../assets/images/trending.png";

import placeholderPhoto from "../assets/images/productPlaceholder.png";
import uploadphotoIcon from "../assets/images/image-input.png";

import goldFrame from "../assets/images/Gold Frame.png";
import platFrame from "../assets/images/Platinum Frame.png";
import regLuzFrame from "../assets/images/Regional Luzon.png";
import regVisFrame from "../assets/images/Regional Visayas.png";
import regMinFrame from "../assets/images/Regional Mindanao.png";
import trendingFrame from "../assets/images/Trending Frame.png";
// import { error } from 'console';

window.Buffer = window.Buffer || require("buffer").Buffer; 

async function getURLFromService(link){
    // The real implementation would make a network call here.
    await new Promise((resolve) => setTimeout(resolve, 1000));
    return link;
}

export default function EntryItemWinners(props) {
    const {userToken, setUserToken, loading, setLoading, postLoader, setPostLoader, userData, userAttrAvailable, setUserAttrAvailable } = useAppContext();

    const [imageModalShow, setImageModalShow] = useState(false);
    const [goldModalShow, setGoldModalShow] = useState(false);
    const [platModalShow, setPlatModalShow] = useState(false);
    const [regLuzModalShow, setRegLuzModalShow] = useState(false);
    const [regVisModalShow, setRegVisModalShow] = useState(false);
    const [regMinModalShow, setRegMinModalShow] = useState(false);
    const [trendingModalShow, setTrendingModalShow] = useState(false);
    
    const [showModal, setShowModal] = useState(false);
    const [imgFile, setImgFile] = useState();
    const [imgDisplay , setImgDisplay] = useState(props.photo);

    const [currVotes, setCurrVotes] = useState(0);
    const [requiredVotes, setRequiredVotes] = useState(10000);
    const [categ, setCateg] = useState("Gold");

    const [imgReq_Size, setImgReq_Size] = useState(true);
    const [imgReq_Type, setImgReq_Type] = useState(true);
    const [imgReq_Height, setImgReq_Height] = useState(true);
    const [imgReq_Width, setImgReq_Width] = useState(true); 

    const [url, setUrl] = useState("none");
    const exportRef = useRef();
    const shareButton = useRef();

    const imgLink = "https://cdn.grabfanfaves.com/products/";
    
    useState(()=>{
        // console.log(props);
        if(props.category === "Hot 100"){
            setCateg("Trending Faves");
            setRequiredVotes(5000);
        }
    }, [imgDisplay]);

    const file = useRef(null);
    const changePhoto = (e) => setShowModal(true);
    const handleClose = () => {
        setShowModal(false); 
        setImgReq_Type(true);
        setImgReq_Size(true);
        setImgReq_Height(true);
        setImgReq_Width(true);
        setImgFile();
    };

    const modalClose = () => {
        setImageModalShow(false);
    };

    const goldModalClose = () => {
        setGoldModalShow(false);
    };

    const platModalClose = () => {
        setPlatModalShow(false);
    };

    const regLuzModalClose = () => {
        setRegLuzModalShow(false);
    };

    const regVisModalClose = () => {
        setRegVisModalShow(false);
    };

    const regMinModalClose = () => {
        setRegMinModalShow(false);
    };

    const trendingModalClose = () => {
        setTrendingModalShow(false);
    };

    const acceptedFileTypes = ["image/png", "image/jpg", "image/jpeg", "image/webp"];

    useEffect(() => {
        if(props.votes !== null){
            setCurrVotes(props.votes);
        }
    }, [imgReq_Size, imgReq_Height, imgReq_Width, imgReq_Type]);

    function handleFileChange(event) {
        setLoading(true);
        file.current = event.target.files[0];

        var img = new Image();
            img = document.createElement("img");
            img.src = URL.createObjectURL(file.current);
        
        img.onload = function () {
            setImgReq_Type(acceptedFileTypes.includes(file.current.type));
            setImgReq_Size(file.current.size <= 10485760);
            setImgReq_Height(img.height >= 1080);
            setImgReq_Width(img.width >= 1080);
            setImgFile(img.src);

            setLoading(false);
        };
    }

    async function handleSubmit(event) {
        setLoading(true);
        event.preventDefault();
        event.stopPropagation();

        if(imgReq_Size && imgReq_Height && imgReq_Width && imgReq_Type){
            uploadFile(file.current, config.s3_product)
                .then(async (s3Response) => {
                    const passData = {};
                        passData["action"] = "uploadphoto";
                        passData["profilepic"] = file.current["name"];
                        passData["mexid"] = userData["mexID"];
                        passData["prodid"] = event.target.getAttribute("data-target");
                        passData["gateway"] = process.env.REACT_APP_GATEWAY;
                        passData["accessToken"] = userToken;

                    const json = JSON.stringify(passData);

                    const response = await fetch(
                        'https://t52t60dk2a.execute-api.ap-southeast-1.amazonaws.com/default/Grab_Merchant', 
                        {
                            method: 'POST',    
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: json
                        });
                    const res = await response; 

                    if(res.status == 200){
                        setImgDisplay( passData["profilepic"]);
                        toast.info('Photo successfully uploaded', config.toast);
                        setShowModal(false);
                        setLoading(false);

                    }
                })
                .catch((err) => {
                    toast.error(err.message, config.toast); 
                    setLoading(false);}
                    );
        }else{
            toast.error('Sorry, your photo does not meet the requirements.', config.toast);
            setLoading(false);
            return;
        }
    }

    async function waitForElement(selector, timeout = 5000) {
        const start = Date.now();
        
        while (Date.now() - start < timeout) {
            const el = document.querySelector(selector);
            if (el) {
            return el;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        
        return null;
    }

    async function waitForImageDisplay(link, timeout = 5000) {
        const start = Date.now();
        
        while (Date.now() - start < timeout) {
            const el = await fetch(link);
            if (el) {
                return el;
            }
            await new Promise(resolve => setTimeout(resolve, 1000));
        }
        
        return null;
    }

    async function getImageDiv(award){
        if(props.award){

            switch (award) {
                case "Gold":
                    setGoldModalShow(true);

                    return await html2canvas(await waitForElement('#image-to-fetch-gold'),  { useCORS: true });
            
                case "Platinum":
                    setPlatModalShow(true);

                    return await html2canvas(await waitForElement('#image-to-fetch-plat'),  { useCORS: true });

                case "National Awards - Luzon":
                    setRegLuzModalShow(true);

                    return await html2canvas(await waitForElement('#image-to-fetch-regLuz'),  { useCORS: true });

                case "National Awards - Visayas":
                    setRegVisModalShow(true);

                    return await html2canvas(await waitForElement('#image-to-fetch-regVis'),  { useCORS: true });

                case "National Awards - Mindanao":
                    setRegMinModalShow(true);

                    return await html2canvas(await waitForElement('#image-to-fetch-regMin'),  { useCORS: true });
    
                case "Trending Faves Winners":
                    setTrendingModalShow(true);

                    return await html2canvas(await waitForElement('#image-to-fetch-trending'),  { useCORS: true });

                default:
                    setImageModalShow(true);

                    return await html2canvas(await waitForElement('#image-to-fetch'),  { useCORS: true });
            }

        }else{
            setImageModalShow(true);

            return await html2canvas(await waitForElement('#image-to-fetch'),  { useCORS: true });
        }
    }

    const uploadImage = async(mexname, productname, award) => {
        //setImageModalShow(true);

        if (imgDisplay !== "") {
            const test = await imgLink+imgDisplay;
        }
        
        const canvas = await getImageDiv(award);
        const image = canvas.toDataURL("image/png", 1.0);

        const base64string = image;

        const filename = mexname + '-' + productname + Date.now() + '.png'
        const data = {base64string, filename};

        await fetch('https://gw3ymyb8cj.execute-api.ap-southeast-1.amazonaws.com/default/Grab_base64', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify(data)
        }).then(response => {
            if(response.status == 200){
                if (url === "none") {
                    async function getImage(){
                        const newUrl = await getURLFromService('https://cdn.grabfanfaves.com/socialmedia/' + filename);

                        setUrl(newUrl);
                    }

                    getImage();
                }
            }
        });
    }
    
    useEffect(() => {
        if (url !== "none") {
            shareButton.current?.click();
            setUrl("none");
        }
    }, [url, shareButton]);

    function getImage() {
        if(props.award){
            switch (props.award) {
                case "Gold":
                    return(<img className='award-icon' src={awardGold}/>)
                case "Platinum":
                    return(<img className='award-icon' src={awardPlatinum}/>)
                case "Trending Faves Winners":
                    return(<img className='award-icon' src={awardTrending}/>)
                default:
                    return(<img className='award-icon' src={awardRegional}/>)
            }
        }
    }

    return(
        <>
        <div className={`entry-wrapper ${props.category.replaceAll(' ', '').replaceAll('/','').toLowerCase()}`}>
            {getImage()}
            <div className="entry-photo">
                <img src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} alt="" className="header-icon" />
                {/* <span onClick={changePhoto} data-id={props.id} className='entry-edit-photo' title='Edit Entry Photo'><FontAwesomeIcon icon={faIcon.faEdit}/></span> */}
            </div>
            <div className="p-1 entry-details">
                <p className="entry-name m-0">{props.name}</p>
                <div className="mid-section">
                    <div className="entry-ranking">
                        {(props.rank !== null)&&(props.rank !== undefined) ? 
                            <h2>{props.rank}</h2> :
                            <h4 className='text-bold'>N/A</h4>}
                        <label>RANKING</label>
                    </div>
                    <div className="entry-votes">
                        <h4>{Number(currVotes).toLocaleString()}</h4>
                        <label>TOTAL VOTES</label>
                    </div>
                </div>
                <div className="entry-category">
                    {(props.isRegional) ? 
                        <>
                            <p className='catname-main m-0'>REGIONAL FAVE</p>
                            <p className='catname-sub m-0'>{props.category.split("-")[1]}</p>
                        </>:
                        (props.category == "Hot 100") ? 
                            <p className='catname-main m-0'>TRENDING FAVE</p>
                            :
                            <>
                                <p className='catname-main'>{props.category}</p>
                                <p className='catname-sub'>{(props.award) ? props.award : "National"} Fan Fave</p>
                            </>
                    }
                </div>
                <div className="entry-socials">
                    <FacebookShareButton ref={shareButton} onClick={async () => await uploadImage(userData.mexName, props.name, props.award)} openShareDialogOnClick={url !== "none"} url={url} /*hashtag={'#' + props.name}*/>
                        <a>
                            <FontAwesomeIcon icon={faBrands.faFacebookSquare }/>
                        </a>
                        
                    </FacebookShareButton>
                    {/* <a href="">
                        <FontAwesomeIcon icon={faBrands.faInstagramSquare}/>
                    </a>
                    <a href="">
                        <FontAwesomeIcon icon={faBrands.faTwitterSquare}/>
                    </a> */}
                </div>
            </div>
        </div>

        <Modal show={imageModalShow} onHide={modalClose} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='head-btn-container' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please wait for the share dialog to show up</p>
                <div className="photo-container">
                    <div id='image-to-fetch'/*ref={exportRef}*/ className="parent clearfix" style={{backgroundColor:'#3700ff'}}>
                        <img  className='fgPhoto' src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} crossOrigin='anonymous'></img>
                        <img className='bgPhoto' src={imageFrame}></img>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={goldModalShow} onHide={goldModalClose} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='head-btn-container' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please wait for the share dialog to show up</p>
                <div className="photo-container">
                    <div id='image-to-fetch-gold'/*ref={exportRef}*/ className="parent clearfix" style={{backgroundColor:'#3700ff'}}>
                        <img  className='fgPhoto' src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} crossOrigin='anonymous'></img>
                        <img className='bgPhoto' src={goldFrame}></img>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={platModalShow} onHide={platModalClose} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='head-btn-container' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please wait for the share dialog to show up</p>
                <div className="photo-container">
                    <div id='image-to-fetch-plat'/*ref={exportRef}*/ className="parent clearfix" style={{backgroundColor:'#3700ff'}}>
                        <img  className='fgPhoto' src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} crossOrigin='anonymous'></img>
                        <img className='bgPhoto' src={platFrame}></img>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={regLuzModalShow} onHide={regLuzModalClose} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='head-btn-container' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please wait for the share dialog to show up</p>
                <div className="photo-container">
                    <div id='image-to-fetch-regLuz'/*ref={exportRef}*/ className="parent clearfix" style={{backgroundColor:'#3700ff'}}>
                        <img  className='fgPhoto' src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} crossOrigin='anonymous'></img>
                        <img className='bgPhoto' src={regLuzFrame}></img>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={regVisModalShow} onHide={regVisModalClose} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='head-btn-container' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please wait for the share dialog to show up</p>
                <div className="photo-container">
                    <div id='image-to-fetch-regVis'/*ref={exportRef}*/ className="parent clearfix" style={{backgroundColor:'#3700ff'}}>
                        <img  className='fgPhoto' src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} crossOrigin='anonymous'></img>
                        <img className='bgPhoto' src={regVisFrame}></img>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={regMinModalShow} onHide={regMinModalClose} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='head-btn-container' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please wait for the share dialog to show up</p>
                <div className="photo-container">
                    <div id='image-to-fetch-regMin'/*ref={exportRef}*/ className="parent clearfix" style={{backgroundColor:'#3700ff'}}>
                        <img  className='fgPhoto' src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} crossOrigin='anonymous'></img>
                        <img className='bgPhoto' src={regMinFrame}></img>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={trendingModalShow} onHide={trendingModalClose} aria-labelledby="contained-modal-title-vcenter">
            <Modal.Header className='head-btn-container' closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>Please wait for the share dialog to show up</p>
                <div className="photo-container">
                    <div id='image-to-fetch-trending'/*ref={exportRef}*/ className="parent clearfix" style={{backgroundColor:'#3700ff'}}>
                        <img  className='fgPhoto' src={imgDisplay != "" ? imgLink+imgDisplay : placeholderPhoto} crossOrigin='anonymous'></img>
                        <img className='bgPhoto' src={trendingFrame}></img>
                    </div>
                </div>
            </Modal.Body>
        </Modal>

        <Modal show={showModal} onHide={handleClose} className='edit-profile-modal'>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <h4 className="text-center m-auto mb-3">UPLOAD PRODUCT PHOTO</h4>
                <form action="" method="post" className='form-update_product_photo text-center'>
                    {/* <p className="mb-1">Click photo to select:</p> */}
                    <div className={`imagefile-wrapper m-auto ${(imgDisplay ? "withImage" : "")}`}>
                        <input type="file" onChange={handleFileChange} name="photo" id="product-photo" accept="image/png, image/jpg, image/jpeg, image/webp"/>
                        <img src={(imgFile) ? imgFile : (imgDisplay ? imgLink+imgDisplay : uploadphotoIcon)} />
                    </div>
                    <div className="imagefile-reqs m-auto mt-2">
                        <p className={imgReq_Size?"correct":"wrong"}>Max Size : <span className='text-underline'>10mb</span> </p>
                        <p className={imgReq_Type?"correct":"wrong"}>Format: <span className='text-underline'> JPEG, JPG, PNG, WebP</span> </p>
                        <p className={imgReq_Width&&imgReq_Height?"correct":"wrong"}>Min Dimensions: <span  className='text-underline'>1080px x 1080px</span> </p>
                    </div>
                    <input data-target={props.id}  type="submit" data-phase="updateProfile" className='btn btn-main-green btn-otp mt-3' value="UPLOAD IMAGE" onClick={handleSubmit}/>
                </form>
            </Modal.Body>
        </Modal>
        </>
    );
}

// https://grabfanfaves.s3.ap-southeast-1.amazonaws.com/products/