
import React, {useEffect, useState} from 'react';
import { Fade, Zoom, Slide } from "react-awesome-reveal";
import { useAppContext } from '../../libs/contextLib';

export default function Regional(props) {
    const {activeRegion, setActiveRegion, regionalMerchants, setRegionalMerchants, isMobile} = useAppContext();	


    const [luzon, setLuzon] = useState([]);
    const [visayas, setVisayas] = useState([]);
    const [mindanao, setMindanao] = useState([]);

    const regions = ["Luzon", "Visayas", "Mindanao"];

    useEffect(() => {
        if(isMobile){
            getAllFromCategory(activeRegion, false);
        }else{
            regions.forEach((value, index) => {
                getAllFromCategory(value, true);
            })
        }
    },[]);

    async function getAllFromCategory(category, isAll){
        setRegionalMerchants([]);

        try {
            const data = {};
                data["action"] = "getallfromonecat";
                data["category"] = "Regional Faves-"+category;
                data["gateway"] = process.env.REACT_APP_GATEWAY;

            var json = JSON.stringify(data);

            const response = await fetch(
                "https://k85nelxwo8.execute-api.ap-southeast-1.amazonaws.com/default/Grab_Items", 
                {
                    method: 'POST',    
                    headers: {'Content-Type': 'application/json'},        
                    body: json
                });
            const res = await response.json(); 

            const placeholder = [];
            res[0].items.forEach((value, index) =>{
                if(!value.fake){
                    placeholder.push({"merchant": value.merchant, "link": value.link, "category": value.category, "votes": value.votes});
                }
            })

            
            // const merchantList = [...new Set(placeholder)].sort();
            const merchantList = placeholder
                .filter((item, pos) => {
                    const currIndex = placeholder.findIndex(x => x.merchant === item.merchant);
                    return currIndex == pos;
                })
                .sort((a, b) => {
                    let fa = a.votes,
                        fb = b.votes;
                
                    if (fa < fb) {
                        return 1;
                    }
                    if (fa > fb) {
                        return -1;
                    }
                    return 0;
                });
            
            const items = [];
            merchantList.forEach((value, index) => {
                if(index <= 4){
                    items.push(
                        <div className='regions-item-wrapper' key={value.merchant} >
                            <span><b className="rank">{index+1}</b></span>
                            <a  
                                href={value.link} 
                                target='_blank'
                                className='results-item-regional'>
                                {value.merchant}
                            </a>
                        </div>
                    );
                }
            });

            setTimeout(() => {
                if(isAll){
                    switch (category) {
                        case "Luzon": setLuzon(items) 
                            break;
                        case "Visayas": setVisayas(items) 
                            break;
                        case "Mindanao": setMindanao(items) 
                            break;
                    }
                }else{
                    setRegionalMerchants(items);
                }
            }, 200);
        } catch (error) {
            alert(error);
        }
        
    }

    async function getItems(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const region = e.target.closest("button").getAttribute("region").split("-")[1];

        if(region != activeRegion){
            setActiveRegion(region);
            getAllFromCategory(region);
        }
    }
    
    return(
        <>
        <div className={`regions-wrapper ${activeRegion}`}>
            {isMobile ? 
            <>
                <div className="regions-tabs luz">
                    <button id="region-Luzon" region="Regional Faves-Luzon" onClick={getItems}>
                        <span>Luzon</span>
                    </button>
                </div>
                <div className="regions-tabs vis">
                    <button id="region-Visayas" region="Regional Faves-Visayas" onClick={getItems}>
                        <span>Visayas</span>
                    </button>
                </div>
                <div className="regions-tabs min">
                    <button id="region-Mindanao" region="Regional Faves-Mindanao" onClick={getItems}>
                        <span>Mindanao</span>
                    </button>
                </div>
                <div className="regions-panel">
                    {regionalMerchants.length == 0 ? 
                        <div className='results-loader'>
                            <div className="results-loader-wrapper">
                                <div className="lds-loader"><div></div><div></div><div></div></div>
                            </div>
                        </div>
                        :
                        <div className="regions-panel-inner-wrapper">
                            {regionalMerchants}
                        </div>
                    }
                </div>
            </>
            :
            <>
            <div className="regional desktop">
                <section className="luzon">
                    <div className="section-header">
                        LUZON
                    </div>
                    <div className="section-body">
                        {luzon}
                    </div>
                </section>
                <section className="visayas">
                    <div className="section-header">
                        VISAYAS
                    </div>
                    <div className="section-body">
                        {visayas}
                    </div>
                </section>
                <section className="mindanao">
                    <div className="section-header">
                        MINDANAO
                    </div>
                    <div className="section-body">
                        {mindanao}
                    </div>
                </section>
            </div>
            </>}
        </div>
        </>
    );
}