// const config = {
//     cognito: {
//         REGION: "ap-southeast-1",
//         USER_POOL_ID: "ap-southeast-1_4LmnMxvH9",
//         APP_CLIENT_ID: "5rru8p941bbp3u24g4j9lj5ukf",
//         IDENTITY_POOL_ID: "ap-southeast-1:20a624da-52b3-4f03-b303-c0ff1d51af41",
//     },
//   };
  
//   export default config;

const config = {
    s3: {
        bucketName: "grabfanfaves-dgfp3wdepfn8prbg",
        region: "ap-southeast-1",
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        s3Url: "https://grabfanfaves-dgfp3wdepfn8prbg.s3.ap-southeast-1.amazonaws.com",
    },
    s3_product: {
        bucketName: "grabfanfaves-dgfp3wdepfn8prbg",
        region: "ap-southeast-1",
        dirName: "products",
        // accessKeyId: "AKIA3AISJUKLIK5LRTRW",
        // secretAccessKey: "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",
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        s3Url: "https://grabfanfaves-dgfp3wdepfn8prbg.s3.ap-southeast-1.amazonaws.com",
    },
    s3_profile: {
        bucketName: "grabfanfaves-dgfp3wdepfn8prbg",
        region: "ap-southeast-1",
        dirName: "profilepicture",
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        s3Url: "https://grabfanfaves-dgfp3wdepfn8prbg.s3.ap-southeast-1.amazonaws.com",
    },
    cognito: {
        REGION: "ap-southeast-1",
        USER_POOL_ID: "ap-southeast-1_XSVfIbiA9",
        APP_CLIENT_ID: "5ns4bjgfh8amu78s9vnedv697c",
        USER_POOL_APP_CLIENT_ID: "5ns4bjgfh8amu78s9vnedv697c",
        IDENTITY_POOL_ID: "ap-southeast-1:20a624da-52b3-4f03-b303-c0ff1d51af41",
    },
    toast: {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        theme: "colored",
    }
};
  
export default config;