
import React, {useEffect, useState} from 'react';
import { Fade, Zoom, Slide } from "react-awesome-reveal";
import { useAppContext } from '../../libs/contextLib';
import ReactPaginate from 'react-paginate';

export default function Trending(props) {
    const {trendings, setTrendings} = useAppContext();	

    useEffect(() => {
        getAllFromCategory();
    },[]);

    async function getAllFromCategory(){
        setTrendings([]);

        try {
            const data = {};
                data["action"] = "getallfromonecat";
                data["category"] = "Hot 100";
                data["gateway"] = process.env.REACT_APP_GATEWAY;

            var json = JSON.stringify(data);

            const response = await fetch(
                "https://k85nelxwo8.execute-api.ap-southeast-1.amazonaws.com/default/Grab_Items", 
                {
                    method: 'POST',    
                    headers: {'Content-Type': 'application/json'},        
                    body: json
                });
            const res = await response.json(); 

            const placeholder = [];
            res[0].items.forEach((value, index) =>{
                if(!value.fake){
                    placeholder.push({
                        "rank": value.rank,
                        "votes": value.votes,
                        "merchant": value.merchant, 
                        "link": value.link, 
                        "product": value.product_name, 
                        "category": value.category, 
                    });
                }
            });

            // console.log(placeholder)

            // const merchantList = [...new Set(placeholder)].sort();
            const merchantList = placeholder
                // .filter((item, pos) => {
                //     const currIndex = placeholder.findIndex(x => x.merchant === item.merchant);
                //     return currIndex == pos;
                // })
                .filter((item, pos) => {
                    if(item.category == "Hot 100"){
                        return item;
                    }
                })
                .map((item, pos) => {
                    var el = item.product;
                    const length = item.product.length;
                    if(el.charAt(0)=="\"" && el.slice(-1)=="\""){
                        el = el.slice(1, -1);
                    }
                    else if(el.charAt(0)=="\""){
                        el = el.substring(1, length);
                    }
                    else if(el.slice(-1)=="\""){
                        el = el.substring(0, length - 1);
                    }

                    item.product = el;
                    
                    return item;
                })
                .sort((a, b) => {
                    let fa = a.rank,
                        fb = b.rank;
                
                    if (fa < fb) {
                        return -1;
                    }
                    if (fa > fb) {
                        return 1;
                    }
                    return 0;
                });

                // console.log(merchantList);
                // merchantList.forEach((value, index) =>{
                //     value.rank = index+1;
                // });

            setTimeout(() => {
                setTrendings(merchantList);
            }, 200);
        } catch (error) {
            alert(error);
        }
        
    }

    const items = trendings;

    function Items({ currentItems }) {
        return (currentItems && currentItems.map((value, index) => (
            <div key={index+"-"+value.product}>
                <a
                    href={value.link} 
                    target='_blank'
                    className='results-item-trending'>
                    <span className='rank-wrapper'><b className='rank'>{value.rank}</b></span>
                    <span>{value.product}</span>
                    <span className='mex'>{value.merchant}</span>
                </a>
            </div>
        )));
    }
    
    function PaginatedItems({ itemsPerPage }) {
        const [itemOffset, setItemOffset] = useState(0);

        const endOffset = itemOffset + itemsPerPage;
        // console.log(`Loading items from ${itemOffset} to ${endOffset}`);
        const currentItems = items.slice(itemOffset, endOffset);
        const pageCount = Math.ceil(items.length / itemsPerPage);
        
        const handlePageClick = (event) => {
          const newOffset = (event.selected * itemsPerPage) % items.length;
        //   console.log(
        //     `User requested page number ${event.selected}, which is offset ${newOffset}`
        //   );
          setItemOffset(newOffset);
        };

        return (
            <>
                <div className="items">
                    <Items currentItems={currentItems} />
                </div>
                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={3}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                />
            </>
        );
    }
    
    return(
        <>
        <div className="trendings-wrapper">
            <PaginatedItems itemsPerPage={10} />
        </div>
        </>
    );
}