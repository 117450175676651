
import React, {useEffect, useState, useRef} from 'react';
import { Fade, Zoom, Slide } from "react-awesome-reveal";
import { useAppContext } from '../../libs/contextLib';

export default function NationalWinners(props) {
    const {activeCategory, setActiveCategory, merchants, setMerchants, categories} = useAppContext();	
    const [loading, setLoading] = useState(true);

    var categoryName = activeCategory.replaceAll('/', '').replaceAll(' ','');
    useEffect(() => {
        setLoading(false);

        getAllFromCategory(activeCategory);
    },[]);

    async function getAllFromCategory(category){
        // console.log(document.getElementById("results-inner-wrapper") != null);
        if(document.getElementById("results-inner-wrapper") != null){
            document.getElementById("results-inner-wrapper").scrollTop = 0;
        }
        setMerchants([]);

        const items = [];

        switch (category) {
            case "Boneless Chicken":
                setMerchants(
                    <>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12728&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12728" target="_blank" >
                                    <span>X-tra Long Chicken Jr. Sandwich</span>
                                    <span>Burger King</span>
                                </a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Coffee":
                setMerchants(
                    <>
                        <div className='platinum results-item'>
                            <img src={require("../../assets/images/platinum.png")} alt="" srcSet="" />
                            <a href='https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12736&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12736' target='_blank' >
                                <span>Caramel Macchiato</span>
                                <span>Starbucks</span>
                            </a>

                        </div>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12591&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12591" target="_blank" >
                                    <span>Kape Kastila</span>
                                    <span>PICKUP COFFEE</span>
                                </a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12725&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12725" target="_blank" >
                                    <span>Iced Coffee Extra Large (22oz)</span>
                                    <span>Dunkin'</span>
                                </a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Pizza":
                setMerchants(
                    <>
                        <div className='platinum results-item'>
                            <img src={require("../../assets/images/platinum.png")} alt="" srcSet="" />
                            <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12589&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12589" target="_blank" ><span>Creamy Spinach Dip Double Deals</span><span>Angel's Pizza</span></a>
                        </div>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12584&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12584" target="_blank" ><span>39% off on Super Supreme</span><span>Pizza Hut</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12616&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12616" target="_blank" ><span>18" Combo</span><span>S&amp;R New York Style Pizza</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Cold Desserts":
                setMerchants(
                    <>
                        <div className='platinum results-item'>
                            <img src={require("../../assets/images/platinum.png")} alt="" srcSet="" />
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12571&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12571" target="_blank" ><span>Extra Creamy Halo-Halo</span><span>Mang Inasal</span></a>
                        </div>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12565&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12565" target="_blank" ><span>SuperSangkap Halo-Halo (Medium)</span><span>Chowking</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Combo Meals":
                setMerchants(
                    <>
                        <div className='platinum results-item'>
                            <img src={require("../../assets/images/platinum.png")} alt="" srcSet="" />
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12732&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12732" target="_blank" ><span>McCrispy Chicken Fillet w/ Fries Medium Meal</span><span>McDonald's</span></a>
                        </div>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12565&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12565" target="_blank" ><span>Chinese-Style Fried Chicken Lauriat</span><span>Chowking</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12594&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12594" target="_blank" ><span>Solo A Roasted Chicken</span><span>Kenny Rogers Roasters</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12559&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12559" target="_blank" ><span>Pizza Loaded Combo E</span><span>Greenwich</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12731&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12731" target="_blank" ><span>Ala King Zinger Steak w/ Corn Combo Meal</span><span>KFC</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12576&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12576" target="_blank" ><span>Original Bunch of Lunch - Grab</span><span>Shakey's Pizza</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12615&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12615" target="_blank" ><span>1-pc Chicken + Rice + Cajun Fries + Drink</span><span>Popeyes Chicken</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Desserts":
                setMerchants(
                    <>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727" target="_blank" ><span>Peach Mango Pie</span><span>Jollibee</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12620&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12620" target="_blank" ><span>LN Classic Donuts</span><span>Lola Nena's</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12599&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12599" target="_blank" ><span>Chocolate Dedication Cake 8x8</span><span>Red Ribbon</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Fried Chicken":
                setMerchants(
                    <>
                        <div className='platinum results-item'>
                            <img src={require("../../assets/images/platinum.png")} alt="" srcSet="" />
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727" target="_blank" ><span>1pc Chickenjoy Solo</span><span>Jollibee</span></a>
                        </div>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12731&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12731" target="_blank" ><span>1-pc Chicken Ala Carte</span><span>KFC</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12733&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12733" target="_blank" ><span>2-PC Korean Fried Chicken Ala Carte</span><span>BonChon</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12695&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12695" target="_blank" ><span>Frankie's Classic Buffalo Regular Wings Premium - with Free Dip!</span><span>Frankie's New York Buffalo Wings</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Fries/Onion RIngs":
                setMerchants(
                    <>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727" target="_blank" ><span>Jolly Crispy Fries</span><span>Jollibee</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12577&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12577" target="_blank" ><span>Tera Fries</span><span>Potato Corner</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12694&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12694" target="_blank" ><span>Freedom Fries</span><span>Army Navy Burger + Burrito</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Healthy Options":
                setMerchants(
                    <>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12737&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12737" target="_blank" ><span>Hail Caesar Wrap!</span><span>SaladStop!</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12735&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12735" target="_blank" ><span>Subway 6" Melt</span><span>Subway</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Milk Tea":
                setMerchants(
                    <>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D6682&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D6682" target="_blank" ><span>Panda Milk Tea</span><span>CoCo Fresh Tea &amp; Juice</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12590&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12590" target="_blank" ><span>Cheesecake and Pearl Milk Tea</span><span>Macao Imperial Tea</span></a>
                            </div>
                        </div>
                    </>
                )

                break;
            case "Pasta":
                setMerchants(
                    <>
                        <div className='platinum results-item'>
                            <img src={require("../../assets/images/platinum.png")} alt="" srcSet="" />
                            <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727" target="_blank" ><span>Jolly Spaghetti Solo</span><span>Jollibee</span></a>
                        </div>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12559&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12559" target="_blank" ><span>Lasagna Supreme</span><span>Greenwich</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12652&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12652" target="_blank" ><span>Spaghetti with Meat Sauce</span><span>Amber</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Premium Burger":
                setMerchants(
                    <>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12728&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12728" target="_blank" ><span>4-Cheese Whopper Jr. Meal</span><span>Burger King</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12732&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12732" target="_blank" ><span>Big Mac w/ Fries Medium Meal</span><span>McDonald's</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Regular Burger":
                setMerchants(
                    <>
                        <div className='platinum results-item'>
                            <img src={require("../../assets/images/platinum.png")} alt="" srcSet="" />
                            <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12727" target="_blank" >
                                <span>Yumburger Solo</span>
                                <span>Jollibee</span>
                            </a>
                        </div>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12728&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12728" target="_blank" >
                                    <span>Whopper Jr. Meal</span>
                                    <span>Burger King</span>
                                </a>
                            </div>
                        </div>
                    </>
                )

                break;
            case "Rice Meal":
                setMerchants(
                    <>
                        <div className='platinum results-item'>
                            <img src={require("../../assets/images/platinum.png")} alt="" srcSet="" />
                            <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12565&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12565" target="_blank" ><span>Siomai Chao Fan</span><span>Chowking</span></a>
                        </div>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12648&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12648" target="_blank" ><span>Fried Dumplings with Hainanese Rice</span><span>Paotsin</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12654&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12654" target="_blank" ><span>Tapsilog</span><span>Sinangag Express</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12653&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12653" target="_blank" ><span>Tapsilog</span><span>Rodic's Diner</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12651&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12651" target="_blank" ><span>Porksilog</span><span>Silogan ni Gian</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12693&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12693" target="_blank" ><span>Gyudon</span><span>Marugame Udon</span></a>
                                <a href="" target="_blank" ><span>Plate</span><span>Panda Express</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
            case "Roasted Chicken":
                setMerchants(
                    <>
                        <div className='platinum results-item'>
                            <img src={require("../../assets/images/platinum.png")} alt="" srcSet="" />
                            <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12571&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12571" target="_blank" ><span>Paa Large - PM1</span><span>Mang Inasal</span></a>
                        </div>
                        <div className='gold results-item'>
                            <img src={require("../../assets/images/gold.png")} alt="" srcSet="" />
                            <div className="gold-wrapper">
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12594&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12594" target="_blank" ><span>Solo B Roasted Chicken</span><span>Kenny Rogers Roasters</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12598&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12598" target="_blank" ><span>Oven Roasted Chicken - Sweet Roast</span><span>Chooks To Go</span></a>
                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&amp;c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&amp;is_retargeting=true&amp;af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12561&amp;af_force_deeplink=true&amp;af_sub5=organic&amp;af_ad=&amp;af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&amp;deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12561" target="_blank" ><span>Manok (Whole)</span><span>Baliwag Lechon Manok</span></a>
                            </div>
                        </div>
                    </>
                )
                break;
        }

        // setMerchants(items);
        
    }

    async function getItems(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const category = e.target.querySelector(".categorybutton").getAttribute("category");
        
        if(category == activeCategory){
            setMerchants("empty");
            setActiveCategory("");
        }else{
            setActiveCategory(category);
            getAllFromCategory(category);
        }
    }

    function getCategories() {
        return categories.map((category)=>{
            const categoryName = category.replaceAll('/', '').replaceAll(' ','');
            if(categoryName == "Hot100"){return};
            return(
                <div key={categoryName+"wrapper"}
                    category={category}
                    onClick={(e)=>getItems(e)}
                    className={`categorybuttonWrapper ${categoryName+"-wrapper"} ${activeCategory==category ? "active" : ""}`}>
                <Zoom 
                    fraction=".5" 
                    triggerOnce 
                    duration={300} 
                    className={`categorybuttonWrapper ${categoryName+"-wrapper"} ${activeCategory==category ? "active" : ""}`}>
                    <img 
                        key={categoryName}
                        src={require(`../../assets/images/categoryButtons/${categoryName}${activeCategory==category?"":"-alt"}.png`)} 
                        id={categoryName}
                        className={`categorybutton`}
                        alt={category} 
                        category={category}
                        />  
                </Zoom>
                </div>
            );
        })
    }
    
    return(
        <>
        <div className={`categories-wrapper ${categoryName}`}>
            {getCategories()}
            {(merchants == "empty") ? "":
                <Zoom fraction=".5" triggerOnce duration={300} className="results-wrapper" id="results-wrapper">
                    <div className="results-inner-wrapper winners" id='results-inner-wrapper '>
                        {merchants}
                    </div>
                </Zoom>
            }
        </div>
        </>
    );
}