import React, {useState, useEffect} from 'react';
import { useAppContext } from '../libs/contextLib';
// import "../assets/styles/leaderboard-alt.scss";
import "../assets/styles/leaderboard.scss";
import "../assets/styles/leaderboard-winners.scss";

import CATEGORY from '../components/Sections/Category';
import REGIONAL from '../components/Sections/Regional';
import TRENDING from '../components/Sections/Trending';
import NationalWinners from '../components/Sections/NationalWinners';


export default function LeaderboardWinners() {
    const {activeRegion, setActiveRegion, loading, setLoading, postLoader, setPostLoader, categories, activeCategory, setActiveCategory, merchants, setMerchants, isMobile} = useAppContext();
    const [activeTab, setActiveTab] = useState("national");
    const [trendingPage, setTrendingPage] = useState(1);

    useEffect(() => {
        setLoading(false);
        setPostLoader(true);
    }, []);

    async function getItems(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const region = e.target.closest("button").getAttribute("region").split("-")[1];

        if(region != activeRegion){
            setActiveRegion(region);
        }
    }

    return(
        <section className={`userpage leaderboard leaderboard-winners active-${activeTab}`}>
            <h2 className='header-title m-0'>
                <span className='grabv2'>
                    The Winners of Fan Faves 2023
                </span>
            </h2>
            <h4 className='header-title mb-3 text-bold'>
                {(activeTab == "regional") ? "Certified Faves in the Region":
                (activeTab == "trending") ? "The Trending Faves for 2023!" : 
                "The country's ultimate and people-approved faves"}
            </h4>
            <div className="leaderboard-tabs gffTabs">
                <div className="gffTabs-tabs">
                    <div className={`gffTabs-tab national ${activeTab=="national" ? "active" : ""}`} 
                        data-tab="national"
                        onClick={()=>setActiveTab("national")}>
                        <span>National Faves</span></div>
                    <div className={`gffTabs-tab regional ${activeTab=="regional" ? "active" : ""}`} 
                        data-tab="regional"
                        onClick={()=>setActiveTab("regional")}>
                        <span>Regional Faves</span></div>
                    <div className={`gffTabs-tab trending ${activeTab=="trending" ? "active" : ""}`} 
                        data-tab="trending"
                        onClick={()=>setActiveTab("trending")}>
                        <span>Trending Faves</span></div>
                </div>
                <div className="gffTabs-panels">
                    {activeTab=="national" &&
                        <div className="gffTabs-panel national" data-target="national">
                            <NationalWinners/>
                        </div>
                    }
                    {activeTab=="regional" &&
                        <div className="gffTabs-panel lb regional regional-tabbed regions-wrapper" data-target="regional">
                            <div className={`regions-wrapper ${activeRegion}`}>
                                {isMobile ? 
                                <>
                                    <div className="regions-tabs">
                                        <button id="region-Luzon" region="Regional Faves-Luzon" onClick={getItems}>
                                            <span>Luzon</span>
                                        </button>
                                        <button id="region-Visayas" region="Regional Faves-Visayas" onClick={getItems}>
                                            <span>Visayas</span>
                                        </button>
                                        <button id="region-Mindanao" region="Regional Faves-Mindanao" onClick={getItems}>
                                            <span>Mindanao</span>
                                        </button>
                                    </div>
                                    <div className="regions-panel">
                                        <div className="regions-panel-inner-wrapper">
                                            {
                                                (activeRegion == "Luzon") ?
                                                <>
                                                <div className='regions-item-wrapper' >
                                                    <span><b className="rank">1</b></span>
                                                    <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12611&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12611" target='_blank' className='results-item-regional'>
                                                        La Brent Tea and Cafe
                                                        <small>Baguio</small>
                                                    </a>
                                                </div>
                                                <div className='regions-item-wrapper' >
                                                    <span><b className="rank">2</b></span>
                                                    <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12556&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12556" target='_blank' className='results-item-regional'>
                                                        HODORI KOREAN RESTAURANT
                                                        <small>Baguio</small>
                                                    </a>
                                                </div>
                                                <div className='regions-item-wrapper' >
                                                    <span><b className="rank">3</b></span>
                                                    <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12550&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12550" target='_blank' className='results-item-regional'>
                                                        Ricebox Juicebox
                                                        <small>Pampanga</small>
                                                    </a>
                                                </div>
                                                </> : 
                                                ((activeRegion == "Visayas") ? 
                                                <>
                                                <div className='regions-item-wrapper' >
                                                    <span><b className="rank">1</b></span>
                                                    <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12608&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12608" target='_blank' className='results-item-regional'>
                                                        Jafar's Shawarma Station
                                                        <small>Cebu</small>
                                                    </a>
                                                </div>
                                                <div className='regions-item-wrapper' >
                                                    <span><b className="rank">2</b></span>
                                                    <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12629&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12629" target='_blank' className='results-item-regional'>
                                                        Orange Brutus
                                                        <small>Cebu</small>
                                                    </a>
                                                </div>
                                                <div className='regions-item-wrapper' >
                                                    <span><b className="rank">3</b></span>
                                                    <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12633&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12633" target='_blank' className='results-item-regional'>
                                                        Prutasan ni Adan
                                                        <small>Cebu</small>
                                                    </a>
                                                </div>
                                                </>:
                                                <>
                                                <div className='regions-item-wrapper' >
                                                    <span><b className="rank">1</b></span>
                                                    <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12644&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12644" target='_blank' className='results-item-regional'>
                                                        Wow Lechon
                                                        <small>Davao</small>
                                                    </a>
                                                </div>
                                                <div className='regions-item-wrapper' >
                                                    <span><b className="rank">2</b></span>
                                                    <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12674&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12674" target='_blank' className='results-item-regional'>
                                                        Frkn' Brgrs
                                                        <small>CDO</small>
                                                    </a>
                                                </div>
                                                <div className='regions-item-wrapper' >
                                                    <span><b className="rank">3</b></span>
                                                    <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12545&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12545" target='_blank' className='results-item-regional'>
                                                        Chingkeetea
                                                        <small>CDO</small>
                                                    </a>
                                                </div>
                                                </>
                                                )
                                            }
                                        </div>
                                    </div>
                                </>
                                :
                                <div className="regional desktop">
                                    <section className="luzon">
                                        <div className="section-header">
                                            LUZON
                                        </div>
                                        <div className="section-body">
                                            <div className='regions-item-wrapper' >
                                                <span><b className="rank">1</b></span>
                                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12611&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12611" target='_blank' className='results-item-regional'>
                                                    La Brent Tea and Cafe <br />
                                                    <small>Baguio</small>
                                                </a>
                                            </div>
                                            <div className='regions-item-wrapper' >
                                                <span><b className="rank">2</b></span>
                                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12556&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12556" target='_blank' className='results-item-regional'>
                                                    HODORI KOREAN RESTAURANT <br />
                                                    <small>Baguio</small>
                                                </a>
                                            </div>
                                            <div className='regions-item-wrapper' >
                                                <span><b className="rank">3</b></span>
                                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12550&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12550" target='_blank' className='results-item-regional'>
                                                    Ricebox Juicebox <br />
                                                    <small>Pampanga</small>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="visayas">
                                        <div className="section-header">
                                            VISAYAS
                                        </div>
                                        <div className="section-body">
                                            <div className='regions-item-wrapper' >
                                                <span><b className="rank">1</b></span>
                                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12608&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12608" target='_blank' className='results-item-regional'>
                                                    Jafar's Shawarma Station <br />
                                                    <small>Cebu</small>
                                                </a>
                                            </div>
                                            <div className='regions-item-wrapper' >
                                                <span><b className="rank">2</b></span>
                                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12629&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12629" target='_blank' className='results-item-regional'>
                                                    Orange Brutus <br />
                                                    <small>Cebu</small>
                                                </a>
                                            </div>
                                            <div className='regions-item-wrapper' >
                                                <span><b className="rank">3</b></span>
                                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12633&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12633" target='_blank' className='results-item-regional'>
                                                    Prutasan ni Adan <br />
                                                    <small>Cebu</small>
                                                </a>
                                            </div>
                                        </div>
                                    </section>
                                    <section className="mindanao">
                                        <div className="section-header">
                                            MINDANAO
                                        </div>
                                        <div className="section-body">
                                            <div className='regions-item-wrapper' >
                                                <span><b className="rank">1</b></span>
                                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12644&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12644" target='_blank' className='results-item-regional'>
                                                    Wow Lechon <br />
                                                    <small>Davao</small>
                                                </a>
                                            </div>
                                            <div className='regions-item-wrapper' >
                                                <span><b className="rank">2</b></span>
                                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12674&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12674" target='_blank' className='results-item-regional'>
                                                    Frkn' Brgrs <br />
                                                    <small>CDO</small>
                                                </a>
                                            </div>
                                            <div className='regions-item-wrapper' >
                                                <span><b className="rank">3</b></span>
                                                <a href="https://grab.onelink.me/2695613898?pid=facebook-organic&c=PH_NA_PAX_GF_ALL_LOC__PH23GFPDF2FANFAVES23_Broad&is_retargeting=true&af_dp=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12545&af_force_deeplink=true&af_sub5=organic&af_ad=&af_web_dp=https%3A%2F%2Fgrabfanfaves.com%2F&deep_link_value=grab%3A%2F%2Fopen%3FscreenType%3DGRABFOOD%26categoryShortcutID%3D12545" target='_blank' className='results-item-regional'>
                                                    Chingkeetea <br />
                                                    <small>CDO</small>
                                                </a>
                                            </div>
                                            
                                        </div>
                                    </section>
                                </div>
                                }
                            </div>
                        </div>
                    }
                    {activeTab=="trending" &&
                        <div className="gffTabs-panel lb trending" data-target="trending">
                            {/* <TRENDING/> */}<div className="trendings-wrapper">
                            <div className="items">
                                {(trendingPage == 1) ? 
                                    <>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>1</b></span>
                                            <span>Chicken Macaroni Salad</span>
                                            <span className='mex'>Tropical Hut Hamburger</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>2</b></span>
                                            <span>Gyudon</span>
                                            <span className='mex'>Yoshinoya</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>3</b></span>
                                            <span>Buy 1, Take 1 Large Iced White Chocolate Mocha</span>
                                            <span className='mex'>Seattle's Best Coffee</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>4</b></span>
                                            <span>Clubhouse Sandwich - Solo</span>
                                            <span className='mex'>Tropical Hut Hamburger</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>5</b></span>
                                            <span>House Crispy Sisig</span>
                                            <span className='mex'>Manam</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>6</b></span>
                                            <span>Soy Garlic Chicken Karaage Bowl (Best Seller!)</span>
                                            <span className='mex'>99 Peso Sulit Chicken</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>7</b></span>
                                            <span>Double Double Box</span>
                                            <span className='mex'>BOK Korean Fried Chicken</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>8</b></span>
                                            <span>Chicken Pad Thai</span>
                                            <span className='mex'>Thai Mango</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>9</b></span>
                                            <span>Chicken Sandwich - Solo</span>
                                            <span className='mex'>Tropical Hut Hamburger</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>10</b></span>
                                            <span>Siksik Pandesal 10 pcs</span>
                                            <span className='mex'>Balai Pandesal</span>
                                        </a>
                                    </div>
                                    </>
                                    :
                                    <>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>11</b></span>
                                            <span>Mini Kimbap - Spicy Pork (5pcs)</span>
                                            <span className='mex'>Happilee Korean Kitchen</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>12</b></span>
                                            <span>Shrimp Siomai</span>
                                            <span className='mex'>David's Tea House</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>13</b></span>
                                            <span>Butao King</span>
                                            <span className='mex'>Ramen Nagi </span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>14</b></span>
                                            <span>King Tapa</span>
                                            <span className='mex'>Tatay Pepe's Tapsilogan </span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>15</b></span>
                                            <span>Special BBQ</span>
                                            <span className='mex'>Lerma's Barbecue</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>16</b></span>
                                            <span>Super Taro Ice</span>
                                            <span className='mex'>Quickly</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>17</b></span>
                                            <span>Lugaw with Egg</span>
                                            <span className='mex'>Lugaw Pilipinas</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>18</b></span>
                                            <span>Mango Graham</span>
                                            <span className='mex'>Mango Royal Milkshake</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>19</b></span>
                                            <span>Chicken Rice</span>
                                            <span className='mex'>Tiong Bahru Boneless Hainanese Chicken Rice</span>
                                        </a>
                                    </div>
                                    <div>
                                        <a href="" target='_blank' className='results-item-trending'>
                                            <span className='rank-wrapper'><b className='rank'>20</b></span>
                                            <span>Inato 2</span>
                                            <span className='mex'>Penong's Barbeque & Seafood Grill</span>
                                        </a>
                                    </div>
                                    </>
                                }
                            </div>
                            <div className="trending-page-button-wrapper">
                                <button className={(trendingPage == 1) && "active"} onClick={(e)=>setTrendingPage(1)}>1-10</button>
                                <button className={(trendingPage == 2) && "active"} onClick={(e)=>setTrendingPage(2)}>11-20</button>
                            </div>
                        </div>
                        </div>
                    }
                </div>
            </div>
        </section>
    );
}