import React, {useRef, useState, useEffect} from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import * as faIcon from '@fortawesome/free-solid-svg-icons';
// import * as faBrands from '@fortawesome/free-brands-svg-icons';
import EntryCategory from '../components/EntryCategory';

import { useAppContext } from '../libs/contextLib';

import "../assets/styles/dashboard-owc.scss"
import "../assets/styles/dashboard-wc.scss"
import EntryItem from '../components/EntryItemWinners';

export default function DashboardWinners() {
    const {userToken, setUserToken, loading, setLoading, postLoader, setPostLoader, userData, categories, setCategories, userAttrAvailable, setUserAttrAvailable , 
        nationalWinners, setNationalWinners,
        regionalWinners, setRegionalWinners,
        trendingFaveWinners, setTrendingFaveWinners,
        nationalItems, setNationalItems,
        regionalItems, setRegionalItems,
        trendingFaveItems, setTrendingFaveItems,} = useAppContext();
    const [isPlural, setPlural] = useState(true);
    const [activeTab, setActiveTab] = useState("national");
    const [finalCount, setFinalCount] = useState(0);

    var nationalItemsPlaceholder = [];
    var regionalItemsPlaceholder = [];
    var trendingFaveItemsPlaceholder = [];
    var nationalWinnersPlaceholder = [];
    var regionalWinnersPlaceholder = [];
    var trendingFaveWinnersPlaceholder = [];

    function getEntries() {
        if(userData){
            if(userData.length != 0){
                if((Object.keys(userData.entries).length != 0) && userData.entries != "{}" && userData.entries.length){
                    // console.log(userData.entries)

                    const products = userData.entries
                        .map((item, pos) => {
                            var el = item.name;
                            const length = item.name.length;
                            if(el.charAt(0)=="\"" && el.slice(-1)=="\""){
                                el = el.slice(1, -1);
                            }
                            else if(el.charAt(0)=="\""){
                                el = el.substring(1, length);
                            }
                            else if(el.slice(-1)=="\""){
                                el = el.substring(0, length - 1);
                            }
        
                            item.name = el;
                            
                            return item;
                        })
                        .sort((a, b) => {
                            let fa = a.rank,
                                fb = b.rank;
                        
                            if (fa < fb) {
                                return -1;
                            }
                            if (fa > fb) {
                                return 1;
                            }
                            return 0;
                        });

                    products.map((entryItem, i, row) => {
                        // console.log(entryItem.award, categories.includes(entryItem.category), entryItem.category == "Hot 100");

                        if(categories.includes(entryItem.category)){
                            if(entryItem.category != "Hot 100"){
                                if(entryItem.award !== undefined){
                                    nationalWinnersPlaceholder.push(entryItem);
                                }else{
                                    nationalItemsPlaceholder.push(entryItem);
                                }
                            }else{
                                if(entryItem.award !== undefined){
                                    trendingFaveWinnersPlaceholder.push(entryItem);
                                }else{
                                    trendingFaveItemsPlaceholder.push(entryItem);
                                }
                            }
                        }else{
                            if(entryItem.award !== undefined){
                                regionalWinnersPlaceholder.push(entryItem);
                            }else{
                                regionalItemsPlaceholder.push(entryItem);
                            }
                        }
                    });

                    if(nationalItemsPlaceholder.length !== 0){
                        var elPlaceholder = [];
                        nationalItemsPlaceholder.map((item, i, row) => {
                            elPlaceholder.push(
                                <EntryItem 
                                    key={item.name} 
                                    name={item.name} 
                                    id={item.id} 
                                    photo={item.profilepic} 
                                    votes={item.votes}
                                    rank={item.rank}
                                    category={item.category}
                                    />
                            )
                        });
                        setNationalItems(elPlaceholder);
                    }
                    if(regionalItemsPlaceholder.length !== 0){
                        var elPlaceholder = [];
                        regionalItemsPlaceholder.map((item, i, row) => {
                            elPlaceholder.push(
                                <EntryItem 
                                    key={item.name} 
                                    name={item.name} 
                                    id={item.id} 
                                    photo={item.profilepic} 
                                    votes={item.votes}
                                    rank={item.rank}
                                    category={item.category}
                                    isRegional={true}
                                    />
                            )
                        });
                        setRegionalItems(elPlaceholder);
                    }
                    if(trendingFaveItemsPlaceholder.length !== 0){
                        var elPlaceholder = [];
                        trendingFaveItemsPlaceholder.map((item, i, row) => {
                            elPlaceholder.push(
                                <EntryItem 
                                    key={item.name} 
                                    name={item.name} 
                                    id={item.id} 
                                    photo={item.profilepic} 
                                    votes={item.votes}
                                    rank={item.rank}
                                    category={item.category}
                                    />
                            )
                        });
                        setTrendingFaveItems(elPlaceholder);
                    }
                    if(nationalWinnersPlaceholder.length !== 0){
                        var elPlaceholder = [];
                        nationalWinnersPlaceholder.map((item, i, row) => {
                            elPlaceholder.push(
                                <EntryItem 
                                    key={item.name} 
                                    name={item.name} 
                                    id={item.id} 
                                    photo={item.profilepic} 
                                    votes={item.votes}
                                    rank={item.rank}
                                    category={item.category}
                                    award={item.award}
                                    />
                            )
                        });
                        setNationalWinners(elPlaceholder);
                    }
                    if(regionalWinnersPlaceholder.length !== 0){
                        var elPlaceholder = [];
                        regionalWinnersPlaceholder.map((item, i, row) => {
                            elPlaceholder.push(
                                <EntryItem 
                                    key={item.name} 
                                    name={item.name} 
                                    id={item.id} 
                                    photo={item.profilepic} 
                                    votes={item.votes}
                                    rank={item.rank}
                                    category={item.category}
                                    award={item.award}
                                    isRegional={true}
                                    />
                            )
                        });
                        setRegionalWinners(elPlaceholder);
                    }
                    if(trendingFaveWinnersPlaceholder.length !== 0){
                        var elPlaceholder = [];
                        trendingFaveWinnersPlaceholder.map((item, i, row) => {
                            elPlaceholder.push(
                                <EntryItem 
                                    key={item.name} 
                                    name={item.name} 
                                    id={item.id} 
                                    photo={item.profilepic} 
                                    votes={item.votes}
                                    rank={item.rank}
                                    category={item.category}
                                    award={item.award}
                                    />
                            )
                        });
                        setTrendingFaveWinners(elPlaceholder);
                    }

                    setFinalCount(regionalWinnersPlaceholder.length + nationalWinnersPlaceholder.length + trendingFaveWinnersPlaceholder.length);

                    const hasNational = (nationalItemsPlaceholder.length > 0 || nationalWinnersPlaceholder.length > 0);
                    const hasRegional = (regionalItemsPlaceholder.length > 0 || regionalWinnersPlaceholder.length > 0);
                    const hasTrending = (trendingFaveItemsPlaceholder.length > 0 || trendingFaveWinnersPlaceholder.length > 0);

                    if(hasNational && hasRegional && hasTrending){
                        setActiveTab("national");
                    }else if(!hasNational && hasRegional && hasTrending){
                        setActiveTab("regional");
                    }else if(!hasNational && !hasRegional && hasTrending){
                        setActiveTab("trending");
                    }else if(hasNational && !hasRegional && hasTrending){
                        setActiveTab("national");
                    }else if(hasNational && !hasRegional && !hasTrending){
                        setActiveTab("national");
                    }else if(hasNational && hasRegional && !hasTrending){
                        setActiveTab("national");
                    }else if(!hasNational && hasRegional && !hasTrending){
                        setActiveTab("regional");
                    }
                    // console.log(nationalItemsPlaceholder);
                    // console.log(nationalWinnersPlaceholder);
                    // console.log(regionalItemsPlaceholder);
                    // console.log(regionalWinnersPlaceholder);
                    // console.log(trendingFaveItemsPlaceholder);
                    // console.log(trendingFaveWinnersPlaceholder);
                }
            }
        }
    }

    useEffect(() => {

        // console.log(userData);
        if(userAttrAvailable){
            setTimeout(() => {
                setLoading(false);
                setPostLoader(true);
                getEntries();
            }, 1000);
        }
    }, [userToken, userData, userAttrAvailable]);

    const categ = "milktea";

    return(
        <>
        {/* <section className='userpage dashboard'>
            <h4 className='header-title'>{userData.length != 0 ? (Object.keys(userData.entries).length > 2 ? "Entries" : "Entry") : "Entry"}
                <br />
                <span className='page-info'> 
                    {userData.length != 0 ? (Object.keys(userData.entries).length > 2 ? "View all your entries here." : "View your entry here.") : "View your entry here."}
                    &nbsp;You can also track each of your entry's number of votes and rank within their respective category.
                </span>
            </h4>
            <section className="entries">
                {getEntries()}
            </section>
        </section> */}
        <section className={`userpage leaderboard dashboard dashboard-winners active-${activeTab}`}>
            <h6 className='header-title mb-1'>
                {userData.length != 0 ? (Object.keys(userData.entries).length > 2 ? "Entries" : "Entry") : "Entry"}
            </h6>
            <h4 className='header-title'>
                <span className=' grabv2'> 
                    Thank you for being part of GrabFood Fan Faves 2023! <br />
                    {(finalCount == 1) ? "See your final ranking below." :
                        (finalCount > 1) ? "You're a fan fave in multiple categories." :
                        "We hope you will join us again in the future."
                    }
                </span>
            </h4>
            <div className="leaderboard-tabs gffTabs mt-3">
                <div className="gffTabs-tabs">
                    {(nationalItems.length == 0 && nationalWinners.length == 0) ? "" : 
                    <div className={`gffTabs-tab national ${activeTab=="national" ? "active" : ""}`} 
                        data-tab="national"
                        onClick={()=>setActiveTab("national")}>
                        <span>National Faves</span></div>
                    }
                    {(regionalWinners.length == 0 && regionalItems.length == 0) ? "" : 
                    <div className={`gffTabs-tab regional ${activeTab=="regional" ? "active" : ""}`} 
                        data-tab="regional"
                        onClick={()=>setActiveTab("regional")}>
                        <span>Regional Faves</span></div>
                    }
                    {(trendingFaveItems.length == 0 && trendingFaveWinners.length == 0) ? "" : 
                    <div className={`gffTabs-tab trending ${activeTab=="trending" ? "active" : ""}`} 
                        data-tab="trending"
                        onClick={()=>setActiveTab("trending")}>
                        <span>Trending Faves</span></div>
                    }
                </div>
                <div className="gffTabs-panels">
                    {(activeTab=="national" && (nationalItems.length > 0 || nationalWinners.length > 0)) &&
                        <div className="gffTabs-panel national" data-target="national">
                            {(nationalWinners.length > 0) ?
                                <section className="entries winners">
                                    {nationalWinners}
                                </section>
                            :""}
                            {(nationalItems.length > 0) ?
                                <section className="entries">
                                    {nationalItems}
                                </section>
                            :""}
                        </div>
                    }
                    {(activeTab=="regional" && (regionalItems.length > 0 || regionalWinners.length > 0)) &&
                        <div className="gffTabs-panel lb regional regional-tabbed regions-wrapper" data-target="regional">
                            {(regionalWinners.length > 0) ?
                                <section className="entries winners">
                                    {regionalWinners}
                                </section>
                            :""}
                            {(regionalItems.length > 0) ?
                                <section className="entries">
                                    {regionalItems}
                                </section>
                            :""}
                        </div>
                    }
                    {(activeTab=="trending" && (trendingFaveItems.length > 0 || trendingFaveWinners.length > 0)) &&
                        <div className="gffTabs-panel lb trending" data-target="trending">
                            {(trendingFaveWinners.length > 0) ?
                                <section className="entries winners">
                                    {trendingFaveWinners}
                                </section>
                            :""}
                            {(trendingFaveItems.length > 0) ?
                                <section className="entries">
                                    {trendingFaveItems}
                                </section>
                            :""}
                        </div>
                    }
                </div>
            </div>
        </section>
        </>
    );
}
