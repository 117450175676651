import React, {useState, useEffect} from 'react';
import { useAppContext } from '../libs/contextLib';
import { useIsAuthenticated, useAuthUser, useSignOut, useAuthHeader } from 'react-auth-kit'

export default function Logout() {
    const signOut = useSignOut();

    // useEffect(() => {
    //     logout()
    // }, []);

    // function logout() {
    //     signOut();
    // }
    
    return signOut();
}